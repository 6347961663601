import * as React from "react"
import {
  ChakraProvider,
  Box,
  Text,
  HStack,
  Container,
  Input,
  Button,
  Heading,
  Stack,
  Link,
  theme,
} from "@chakra-ui/react"
import { firestore } from './firebase'
import {
  setDoc,
  doc
 } from '@firebase/firestore/lite';
import { Logo } from "./Logo"


export const App = () => {
  const [email, setEmail] = React.useState('')
  const [submitted, setSubmitted] = React.useState(false)
  
  const onSubmit = async () => {
    const docRef = doc(firestore, `waitlist/${email}`)
    await setDoc(docRef, { addedAt: new Date() })
    setEmail('')
    setSubmitted(true)
  }

  return (
    <ChakraProvider theme={theme}>
      <Container maxW='42em' lineHeight='1.8' mb={10}>
          <Box width='100%' textAlign='left' mt={8} mb={8} >
            <Logo h="100px" pointerEvents="none" ml={-5} />
            <Heading mt={3} size='xl' width='100%'>We're no longer working on Snapboard</Heading>
            </Box>
            <Text color='gray.800' fontSize='lg' mt={4}>
            The team is now working a <Link color='blue.500' isExternal href='https://polybase.xyz'>Polybase, a web3 decentralized database</Link>.
          </Text>
        </Container>
    </ChakraProvider>
  )
}