// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getFirestore,
 } from '@firebase/firestore/lite';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCN7oEkR-ksxy_D77o1iPVBv7sD-2MWj8w",
  authDomain: "snapboard-prod.firebaseapp.com",
  projectId: "snapboard-prod",
  storageBucket: "snapboard-prod.appspot.com",
  messagingSenderId: "1062055703904",
  appId: "1:1062055703904:web:d1b9dc1571b81a566fda05",
  measurementId: "G-VNVPV8M08K"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
export const firestore = getFirestore(app);
